<template>
  <el-dialog
    title="分配系统"
    :visible.sync="systemVisible"
    width="700px"
    :close-on-click-modal="false"
    :before-close="closeDialog"
    v-ismove>
    <el-form :model="systemForm">
      <el-form-item label="系统名称" prop="usIds">
        <el-select v-model="systemForm.usIds" placeholder="请选择" multiple>
          <el-option
            v-for="systemCompany in distributionSystemList"
            :label="systemCompany.usName"
            :value="systemCompany.usId"
            :key="systemCompany.usId">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" size="small" @click="submitSystem()">确 定</el-button>
    </div>
    </el-dialog>
</template>

<script>
import { getAuthorizationSystem } from '@/api/account'
import { getAllSystem } from '@/api/privilegeManage'
import { getPostSystemList, userSystemPositionMapEdit } from '@/api/post'
// import { required } from '@/utils/validate'

export default {
  props: {
    systemVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    editData: {
      type: Object,
      default() {
        return {}
      }
    },
    upId: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      systemForm: { usIds: [] },
      distributionSystemList: [], // 分配的系统列表
      postHaveUsIds: [] // 岗位拥有的系统id集合
    }
  },
  mounted() {
    if (this.$store.getters.roles.userInfo.roleId === 1) {
      // 超管 获取所有系统列表
      getAllSystem()
        .then((response) => {
          if (response.data) {
            this.distributionSystemList = response.data
          }
        })
        .catch((err1) => {
          console.log(err1)
        })
    } else {
      // 不是超管 获取授权人的系统
      getAuthorizationSystem(this.$store.getters.roles.userInfo.userId)
        .then((res) => {
          if (res.data) {
            this.distributionSystemList = res.data
          }
        }
        )
    }
  },
  watch: {
    systemVisible: function() {
      if (this.systemVisible) {
        this.getOwnSystem()
      }
    }
  },
  methods: {
    getOwnSystem() {
      // 超管可以分配所有系统 授权的人可以给岗位分配 授权过的系统
      this.postHaveUsIds = []
      this.systemForm.usIds = []
      this.systemForm.upId = this.upId

      // 获取该岗位拥有的系统 并默认勾选
      getPostSystemList(this.upId)
        .then((response2) => {
          // 遍历岗位拥有的系统 === 授权人的系统列表中系统 分配系统弹窗选中这个系统
          for (let i = 0; i < response2.data.length; i++) {
            this.postHaveUsIds.push(response2.data[i].usId)
            for (let j = 0; j < this.distributionSystemList.length; j++) {
              if (response2.data[i].usId === this.distributionSystemList[j].usId) {
                this.systemForm.usIds.push(response2.data[i].usId)
              }
            }
          }
        })
        .catch((err8) => {
          console.log(err8)
        })
    },
    submitSystem() {
      // 取授权人的系统,操作的时候如果删除了 岗位拥有的系统，在岗位拥有的系统也删除，最后合并提交
      this.distributionSystemList.forEach(element => {
        if ((this.postHaveUsIds.indexOf(element.usId) > -1) && (this.systemForm.usIds.indexOf(element.usId) < 0)) {
          this.postHaveUsIds.splice(this.postHaveUsIds.indexOf(element.usId), 1)
        }
      })
      // 选中的系统 + 岗位拥有系统 并集
      console.log(this.systemForm.usIds)
      console.log(this.postHaveUsIds)
      const prams = {
        upId: this.systemForm.upId,
        usIds: Array.from(new Set([...this.systemForm.usIds, ...this.postHaveUsIds]))
      }
      userSystemPositionMapEdit(prams).then(res => {
        if (res.code === '000000') {
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.systemForm.usIds = []
          this.closeDialog()
        }
      })
    },
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style>

</style>
