<template>
  <div v-if="visible">
    <el-dialog :visible="true" title="岗位分配角色" width="800px" :before-close="closeTab" :close-on-click-modal="false">
      <el-row v-loading="loading">
      <el-tree
        ref="tree"
        :data="roleTree"
        :props="defaultProps"
        default-expand-all
        :check-on-click-node="true"
        node-key="roleId"
        :check-strictly="true"
        :filter-node-method="filterNode"
        icon-class="el-icon-d-arrow-right"
        show-checkbox>
      </el-tree>
      </el-row>
      <div slot="footer">
        <el-button size="small" @click="closeTab">关闭</el-button>
        <el-button type="primary" size="small" @click="roleConfClick" :loading="waiting">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRoleSupInfo } from '@/api/systemRole'
import { editPostRole } from '@/api/post'

export default {
  data() {
    return {
      roleTree: [],
      defaultProps: {
        label: 'roleName',
        value: 'roleId',
        children: 'supRoleList'
      },
      loading: false, // 处理节点重新渲染
      waiting: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    upId: {
      type: Number,
      default: null
    },
    sysRoleIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.sysRoleIds)
        })
      }
    }
  },
  mounted() {
    this.getRoleSupList()
  },
  methods: {
    // 获取角色架构
    getRoleSupList() {
      getRoleSupInfo().then(res => {
        if (res.code === '000000') {
          this.roleTree = res.data
        }
      }).catch(error => {
        console.log(error)
      })
    },
    filterNode(value, data) {
      console.log(data)
      if (!value) return true
      return data.rolename.indexOf(value) !== -1
    },
    closeTab() {
      // this.$emit('update:visible', false)
      this.$emit('updateRoleSuccuss')
    },
    roleConfClick() {
      this.waiting = true
      editPostRole({
        sysRoleIds: this.$refs.tree.getCheckedNodes().map(item => item.roleId),
        upId: this.upId
      }).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.closeTab()
        }
        this.waiting = false
      }).catch(() => {
        this.waiting = false
      })
    }
  }

}
</script>

<style>

</style>
