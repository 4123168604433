<template>
  <div class="app-container">
    <el-form :model="searchForm" size="small" ref="searchForm" inline>
      <el-form-item label="公司名称">
        <!-- <el-cascader :options="companyList" v-model="searchForm.ucId" :show-all-levels="false"
            change-on-select @change="companyChange" :props="companyProps" clearable></el-cascader> -->
        <el-select v-model="searchForm.ucId" :disabled="true" style="width:300px">
          <el-option v-for="company in companyList" :label="company.ucName" :value="company.ucId" :key="company.ucId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职位名称">
        <el-input v-model="searchForm.upName" placeholder="职位名" clearable></el-input>
      </el-form-item>
      <el-form-item label="启用/禁用">
        <el-select v-model="searchForm.state" clearable placeholder="账号状态">
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职位ID">
        <el-input v-model.number="searchForm.upId" clearable placeholder="职位ID"></el-input>
      </el-form-item>
      <el-form-item label="部门长称">
        <el-input v-model="searchForm.longName" clearable placeholder="部门长称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="searchData">查询</el-button>
        <el-button size="small" @click="onClear">清空</el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="isLoading"
      :data="dataList"
      border
      height="calc(100% - 120px)"
      style="width: 100%;"
      size="small"
      :header-cell-style="{textAlign:'center', background: '#41A1FF', color: '#ffffff'}">
      <el-table-column prop="upId" label="职位id" width="140px" align="center"></el-table-column>
      <el-table-column prop="ucName" label="公司名称" align="center"></el-table-column>
      <el-table-column prop="upName" label="职位名称" align="center"></el-table-column>
      <el-table-column label="部门长称" prop="longName" align="center"></el-table-column>
      <el-table-column label="职位等级" prop="levelName" align="center"></el-table-column>
      <el-table-column label="启用/禁用" width="100" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" :content="scope.row.state===1?'启用':'禁用'" placement="top">
            <el-switch v-model="scope.row.state"
              @change="changeStatus($event, scope.row, scope.$index)"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="180px">
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="复制" placement="top">
            <i class="table_icons el-icon-document-copy" @click="openCopy(scope.row)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="分配系统" placement="top">
            <svg-icon icon-class="powers" @click="systemSetClick(scope.row.upId)" class="table_icons" style="width: 16px; height: 16px; cursor:pointer;"></svg-icon>
          </el-tooltip>
          <el-tooltip effect="dark" content="授权" placement="top">
            <svg-icon icon-class="empower" @click="emPowerClick(scope.row.upId)" style="width: 16px; height: 16px; cursor:pointer;" class="table_icons"></svg-icon>
          </el-tooltip>
          <el-tooltip effect="dark" content="角色" placement="top">
            <svg-icon icon-class="emrole" @click="emRoleClick(scope.row.upId, scope.row.systemRolePositionVoList)" style="width: 16px; height: 16px; cursor:pointer;" class="table_icons"></svg-icon>
          </el-tooltip>
          <el-tooltip effect="dark" content="导出" placement="top">
            <!-- <svg-icon icon-class="export" @click="exportClick(scope.row.upId)"></svg-icon> -->
            <i class="table_icons el-icon-download" @click="exportClick(scope.row.upId)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="删除" placement="top">
            <i class="table_icons el-icon-delete" @click="deletePost(scope.row.upId)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" style="text-align: center;margin-top:2em">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

    <!-- 权限配置 -->
    <system-set-dialog
      :systemVisible="systemSetVisable"
      :upId="clickUpId"
      @closeDialog="closeDialog('system')"/>

    <!-- 岗位授权 -->
    <!-- <power-set-dialog
      :powerVisible="emPowerVisable"
      :upId="clickUpId"
      @closeDialog="closeDialog('power')"/> -->
    <power-conf v-bind.sync="powerObj"></power-conf>

    <!-- 角色分配 -->
    <role-conf v-bind.sync="roleObj" @updateRoleSuccuss="updateRoleSuccuss"></role-conf>

    <!-- 复制配置 -->
    <copy-set v-bind.sync="copyPermissionObj" @copySuccuss="copySuccuss" @closeCopy="closeCopy"></copy-set>

    <!-- 数据导出 -->
    <export-dialog
      :exportVisible="exportVisible"
      :upId="clickUpId"
      @closeDialog="closeDialog('export')"/>

  </div>
</template>

<script>
import { getPostList, deletePost, getPostSystemList, updateUpState } from '@/api/post'
import { userCompany, getAuthorizationSystem } from '@/api/account'

import systemSet from './components/systemSet'
// import powerSet from './components/powerSet'
import postExport from './components/postExport'
import copySet from './components/copySet'
import powerConf from '@/components/powerConf'
import roleConf from '@/components/roleConf'

export default {
  components: {
    'system-set-dialog': systemSet,
    // 'power-set-dialog': powerSet,
    'export-dialog': postExport,
    'power-conf': powerConf,
    'role-conf': roleConf,
    'copy-set': copySet
  },
  data() {
    return {
      isLoading: false,
      searchForm: {
        upId: null,
        upName: '',
        ucId: Number(process.env.VUE_APP_COMPANY_ID),
        longName: '',
        state: null
      },
      dataList: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      companyList: [{ ucId: Number(process.env.VUE_APP_COMPANY_ID), ucName: process.env.VUE_APP_COMPANY_NAME, ucParentId: 1023, hasMore: false }],
      companyProps: {
        label: 'ucName',
        value: 'ucId',
        children: 'childrenCompanyList'
      },
      exportVisible: false,
      systemSetVisable: false,
      clickUpId: null,
      powerObj: { // 岗位分配权限
        visible: false,
        userId: null,
        systemList: []
      },
      roleObj: {
        visible: false,
        sysRoleIds: [],
        upId: null
      },
      copyPermissionObj: {
        visible: false,
        upId: null,
        state: null
      }
    }
  },
  mounted() {
    this.getListPost()
    // this.getAllCompany()
  },
  methods: {
    // 获取公司下拉列表
    getAllCompany() {
      userCompany()
        .then(response => {
          if (response.code === '000000' && response.data) {
            this.companyList = JSON.parse(JSON.stringify(response.data))
            this.companyList.forEach(item => {
              if (item.hasMore) {
                item.childrenCompanyList = []
              }
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    // 获取岗位表格数据
    getListPost() {
      this.isLoading = true
      const params = {
        // ucId: this.searchForm.ucId ? this.searchForm.ucId[this.searchForm.ucId.length - 1] : null,
        ucId: this.searchForm.ucId,
        upName: this.searchForm.upName,
        upId: this.searchForm.upId,
        longName: this.searchForm.longName,
        state: this.searchForm.state,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      getPostList(params).then(response => {
        if (response.code === '000000' && response.data.list) {
          this.isLoading = false
          this.dataList = response.data.list
          this.total = response.data.total
        }
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    companyChange(val) {
      const id = val[val.length - 1]
      userCompany({ ucParentId: id }).then(response => {
        if (response.code === '000000' && response.data.length > 0) {
          response.data.forEach(item => {
            if (item.hasMore) {
              item.childrenCompanyList = []
            }
          })
          const children = JSON.parse(JSON.stringify(this.companyList))
          this.reFind(id, children, response.data)
          setTimeout(() => {
            this.companyList = children
          }, 100)
        }
      }).catch(err => {
        console.log(err)
      })
      this.$forceUpdate()
    },
    // 递归查询,公司id 级联数据，添加数据（使用参数做key如childrenCompanyList传入，第三层出现问题不显示，因此要改变的字段指定）
    reFind(id, arr, json) {
      arr.forEach(element => {
        if (id === element.ucId) {
          if (json.length > 0) {
            element.childrenCompanyList = json
          }
        } else {
          if (element.childrenCompanyList) {
            this.reFind(id, element.childrenCompanyList, json)
          }
        }
      })
    },
    // 清空
    onClear() {
      this.searchForm = {}
      this.searchForm.ucId = process.env.VUE_APP_COMPANY_ID
      this.searchData()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getListPost()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getListPost()
    },
    searchData() {
      this.pageNum = 1
      this.getListPost()
    },
    deletePost(upId) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true
          deletePost(upId).then(response => {
            if (response.code === '000000') {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.searchData()
            } else {
              this.$message.error('删除失败')
            }
          }).catch(() => {
            this.isLoading = false
          })
        })
        .catch(() => {
          this.isLoading = false
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 点击打开导出弹窗
    exportClick(upId) {
      this.exportVisible = true
      this.clickUpId = upId
    },
    // 打开系统分配弹窗
    systemSetClick(upId) {
      this.systemSetVisable = true
      this.clickUpId = upId
    },
    // 打开角色分配弹窗
    emRoleClick(upId, systemRolePositionVoList) {
      this.roleObj = {
        visible: true,
        sysRoleIds: systemRolePositionVoList && systemRolePositionVoList.length > 0 ? systemRolePositionVoList.map(item => item.roleId) : [],
        upId: upId
      }
    },
    // 角色分配成功 重新查数据回显
    updateRoleSuccuss() {
      this.roleObj = { visible: false }
      this.searchData()
    },
    // 打开授权弹窗
    emPowerClick(upId) {
      // 逻辑更新 系统如果是超管就是所有系统  授权就是  岗位关联的系统和授权的系统 交集
      if (this.$store.getters.roles.userInfo.roleId === 1) {
        // 点击权限获取岗位拥有的系统列表
        getPostSystemList(upId)
          .then(response2 => {
            this.powerObj = {
              visible: true,
              upId: upId,
              systemList: response2.data // 系统列表，
            }
          })
          .catch(err8 => {
            console.log(err8)
          })
      } else {
        // 获取操作人被授权的系统
        getAuthorizationSystem(
          this.$store.getters.roles.userInfo.userId
        ).then((res) => {
          this.powerObj = {
            visible: true,
            upId: upId,
            systemList: res.data // 系统列表，
          }
        })
      }
    },
    closeDialog(type) {
      switch (type) {
        case 'system':
          this.systemSetVisable = false
          break
        // case 'power':
        //   this.emPowerVisable = false
        //   break
        case 'export':
          this.exportVisible = false
          break
        default:
          break
      }
    },
    changeStatus(val, item, index) {
      const str = val === 1 ? '确定启用该岗位？' : '确定禁用该岗位？'
      this.$confirm(str, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const params = {
          upId: item.upId,
          state: val
        }
        updateUpState(params).then(res => {
          if (res.code === '000000') {
            this.$message.success(res.message)
            this.getListPost()
          } else {
            this.$message.error(res.message)
            item.state = val === 1 ? 0 : 1
          }
        }).catch(() => {
          item.state = val === 1 ? 0 : 1
        })
      }).catch(() => {
        item.state = val === 1 ? 0 : 1
      })
    },
    openCopy(item) {
      this.copyPermissionObj.visible = true
      this.copyPermissionObj.upId = item.upId
      this.copyPermissionObj.upName = item.upName
      this.copyPermissionObj.udName = item.udName
      this.copyPermissionObj.state = item.state
    },
    copySuccuss() {
      this.copyPermissionObj.visible = false
      this.getListPost()
    },
    closeCopy() {
      this.copyPermissionObj.visible = false
    }
  }
}
</script>

<style>

</style>
