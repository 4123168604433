<template>
  <div v-if="visible">
    <el-dialog
      title="复制配置"
      :visible="true"
      width="800px"
      :close-on-click-modal="false"
      :before-close="closeDialog"
      v-ismove>
      <p>请选择需要复制的岗位权限</p>
      <el-form :model="powerForm" size="small" :rules="rules" ref="powerForm" inline>
        <el-form-item label="部门" prop="sourceUdId">
          <el-cascader :options="copyUdList" v-model="powerForm.sourceUdId" :show-all-levels="false" placeholder="请选择，可搜索"
            change-on-select @change="copyChangeDepartment" :props="udProps" filterable clearable></el-cascader>
        </el-form-item>
        <el-form-item label="岗位" prop="sourceUpId">
            <el-select
              v-model="powerForm.sourceUpId"
              placeholder="请选择"
              clearable>
              <el-option
                v-for="parent in copyAllPrent"
                :label="parent.upName"
                :value="parent.upId"
                :key="parent.upId"
              ></el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <p>将选中的岗位权限复制到本岗位</p>
      <el-form :model="nowForm" size="small" ref="nowForm" inline>
        <el-form-item label="部门">
          <span style="width:260px">{{ udName }}</span>
        </el-form-item>
        <el-form-item label="岗位">
          <span>{{ upName }}</span>
        </el-form-item>
        <el-form-item label="状态" style="width:100%">
          <el-radio v-model="state" :label="1">启用</el-radio>
          <el-radio v-model="state" :label="0">禁用</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog">取消</el-button>
        <el-button type="primary" size="small" @click="submitPermission('powerForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as validFn from '@/utils/validate'
import { gettree } from '@/api/departmentalList'
import { getIdPostParent, copyPositionInfo } from '@/api/post'
export default {
  props: {
    visible: {
      type: Boolean,
      default() {
        return false
      }
    },
    upId: {
      type: Number,
      default: null
    },
    upName: {
      type: String,
      default: null
    },
    udName: {
      type: String,
      default: null
    },
    state: {
      type: Number,
      default: 0
    }
  },
  watch: {
    visible: function() {
      if (this.visible) {
        this.getDepartment()
      }
    }
  },
  data() {
    return {
      copyUdList: [],
      copyAllPrent: [],
      powerForm: {
        sourceUpId: '',
        sourceUdId: ''
      },
      nowForm: {},
      // checkList: [],
      rules: {
        sourceUpId: [validFn.requiredC()],
        sourceUdId: [validFn.requiredC()]
      },
      ucId: process.env.VUE_APP_COMPANY_ID,
      udProps: {
        value: 'udId',
        label: 'udName',
        children: 'children',
        checkStrictly: true,
        expandTrigger: 'hover'
      }
    }
  },
  methods: {
    getDepartment() {
      gettree(this.ucId)
        .then((response2) => {
          this.copyUdList = response2.data
        })
        .catch((err4) => {
          console.log(err4)
        })
    },
    closeDialog() {
      this.$refs['powerForm'].resetFields()
      this.$emit('closeCopy')
    },
    copyChangeDepartment(udIdList) {
      // 获取直接上级，也就是该部门下所有职位
      getIdPostParent(this.ucId, udIdList[udIdList.length - 1])
        .then((response3) => {
          this.powerForm.upId = ''
          this.copyAllPrent = response3.data

          if (this.copyAllPrent.length === 0) {
            this.powerForm.upId = ''
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    submitPermission(powerForm) {
      this.$refs[powerForm].validate((valid) => {
        if (valid) {
          const params = {
            sourceUdId: this.powerForm.sourceUdId[this.powerForm.sourceUdId.length - 1],
            sourceUpId: this.powerForm.sourceUpId,
            state: this.state,
            targetUpId: this.upId
          }
          copyPositionInfo(params)
            .then((response3) => {
              // 刷新视图，重新调用提交查询表单
              if (response3.code === '000000') {
                this.$refs[powerForm].resetFields()
                this.$message.success('复制成功')
                this.$emit('copySuccuss')
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style>

</style>
